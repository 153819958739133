<template>
  <div class="kt_content_container">
    <div class="row gy-5 g-xl-10">
      <div class="col-xl-5 mb-xl-10">
        <div class="card h-md-100" v-loading="orderStatusChartLoading">
          <div class="card-header border-0 p-5">
            <h3 class="card-title align-items-start flex-column"><span class="card-label fw-bolder fs-3 mb-1">Order Status</span><span class="text-muted mt-1 fw-bold fs-7">Orders By Order Status</span></h3>
            <div class="card-toolbar">
              <el-date-picker class="ml-4" v-model=orderStatusByOrderDate type="daterange" range-separator="To" value-format="YYYY-MM-DD" start-placeholder="Order date" end-placeholder="Order date" size="large" :shortcuts=shortcuts />
              &nbsp;
              <button type="button" @click.prevent="getOrderStatusChartData()" class="btn btn-icon btn-light btn-sm text-right">
                <i class="bi bi-search"></i>
              </button>
              &nbsp;
              <el-tooltip class="box-item" effect="dark" :content="constants.general.REFRESH" placement="top">
                <button type="button" @click.prevent="resetOrderStatusChart()" class="btn btn-icon btn-light btn-sm">
                  <i class="bi bi-arrow-clockwise"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
          <div class="card-body pb-0 text-center pt-0">
            <apexchart v-if="orderStatusChart.series.length > 0" type="pie" width="500" :options="orderStatusChart.option" :series="orderStatusChart.series"></apexchart>
            <label v-if="orderStatusChart.series.length == 0">No Data Found</label>
          </div>
        </div>
      </div>
      <div class="col-xl-7">
        <!--begin::Card widget 4-->
        <div class="card card-flush h-md-50 mb-5 mb-xl-5" v-loading="orderCountLoading">
          <!--begin::Header-->
          <div class="card-header pt-5">
            <!--begin::Title-->
            <div class="card-title d-flex flex-column">
              <!--begin::Info-->
              <div class="d-flex align-items-center">
                <!--begin::Amount-->
                <span class="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">Total Orders - {{ordersCount.total_orders_count}}</span>
                <!--end::Amount-->
              </div>
              <!--end::Info-->
            </div>
            <!--end::Title-->
          </div>
          <!--end::Header-->
          <!--begin::Card body-->
          <div class="card-body pt-2 pb-4 d-flex align-items-center">
            <div class="d-flex flex-center me-5 pt-2">
              <apexchart v-if="ordersCount.series.length > 0" type="donut" width="180" :options="ordersCount.option" :series="ordersCount.series"></apexchart>
              <label v-if="ordersCount.series.length == 0">No Data Found</label>
            </div>
            <!--begin::Labels-->
            <div class="d-flex flex-column content-justify-center w-100">
              <!--begin::Label-->
              <div class="d-flex fs-6 fw-bold align-items-center">
                <!--begin::Bullet-->
                <div class="bullet w-8px h-6px rounded-2 me-3" style="background-color: #a0a1a5"></div>
                <!--end::Bullet-->
                <!--begin::Label-->
                <div class="text-gray-500 flex-grow-1 me-4">Orders</div>
                <!--end::Label-->
                <!--begin::Stats-->
                <div class="fw-boldest text-gray-700 text-xxl-end">{{ordersCount.total_orders}}</div>
                <!--end::Stats-->
              </div>
              <!--end::Label-->
              <!--begin::Label-->
              <div class="d-flex fs-6 fw-bold align-items-center my-3">
                <!--begin::Bullet-->
                <div class="bullet w-8px h-6px rounded-2 me-3" style="background-color: #F1416C"></div>
                <!--end::Bullet-->
                <!--begin::Label-->
                <div class="text-gray-500 flex-grow-1 me-4">Pending Orders</div>
                <!--end::Label-->
                <!--begin::Stats-->
                <div class="fw-boldest text-gray-700 text-xxl-end">{{ordersCount.total_pending_orders}}</div>
                <!--end::Stats-->
              </div>
              <!--end::Label-->
              <!--begin::Label-->
              <div class="d-flex fs-6 fw-bold align-items-center">
                <!--begin::Bullet-->
                <div class="bullet w-8px h-6px rounded-2 me-3" style="background-color: #009EF7"></div>
                <!--end::Bullet-->
                <!--begin::Label-->
                <div class="text-gray-500 flex-grow-1 me-4">Completed Orders</div>
                <!--end::Label-->
                <!--begin::Stats-->
                <div class="fw-boldest text-gray-700 text-xxl-end">{{ordersCount.total_completed_order}}</div>
                <!--end::Stats-->
              </div>
              <!--end::Label-->
            </div>
            <!--end::Labels-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card widget 4-->
        <div class="row g-5 g-xl-8">
          <div class="col-xl-6">
            <StatisticsWidget5 widget-classes="card-xl-stretch mb-xl-8" svg-icon="/media/icons/duotune/ecommerce/ecm008.svg" color="primary" icon-color="white" :title=shippedUnshippedCount.shipped_count description="Shipped Order Products"></StatisticsWidget5>
          </div>
          <div class="col-xl-6">
            <StatisticsWidget5 widget-classes="card-xl-stretch mb-xl-8" svg-icon="/media/icons/duotune/ecommerce/ecm002.svg" color="danger" icon-color="white" :title=shippedUnshippedCount.unshipped_count description="Unshipped Order Products"></StatisticsWidget5>
          </div>
        </div>
      </div>
      <!--begin::Row-->
    </div>

    <div class="row gy-5 g-xl-10">
      <div class="col-xl-5 mb-xl-10">
        <div class="card h-md-100">
          <div class="card-header border-0 p-5">
            <h3 class="card-title align-items-start flex-column"><span class="card-label fw-bolder fs-3 mb-1">Orders Statistics</span><span class="text-muted mt-1 fw-bold fs-7">Platform wise</span></h3>
            <div class="card-toolbar">
              <el-date-picker class="ml-4" v-model=orderStatisticsByOrderDate type="daterange" range-separator="To" value-format="YYYY-MM-DD" start-placeholder="Order date" end-placeholder="Order date" size="large" :shortcuts=shortcuts />
              &nbsp;
              <button type="button" @click.prevent="getOrderStatistics()" class="btn btn-icon btn-light btn-sm text-right">
                <i class="bi bi-search"></i>
              </button>
              &nbsp;
              <el-tooltip class="box-item" effect="dark" :content="constants.general.REFRESH" placement="top">
                <button type="button" @click.prevent="resetOrderStatistics()" class="btn btn-icon btn-light btn-sm">
                  <i class="bi bi-arrow-clockwise"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
          <div class="card-body pb-0" v-loading="orderStatisticsLoading">
            <!-- start code -->
              <apexchart v-if="orderStatistics.orderdata.length > 0" type="line" :options="orderStatisticsChart.options" :series="orderStatisticsChart.series"></apexchart>
              <div class="d-flex flex-center me-5 pt-2" v-if="orderStatistics.orderdata.length == 0">
                <label>No Data Found</label>
              </div>
          </div>
        </div>
      </div>
      <div class="col-xl-7 mb-5 mb-xl-10">
        <div class="card h-md-100">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              Recent Orders
            </h3>
            <div class="card-toolbar">
              <div class="d-flex flex-stack flex-wrap gap-4">
                <div class="d-flex align-items-center fw-bolder">
                  <el-input v-model="orderNo" placeholder="Order No." class="input-with-select me-2" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                    <template #append>
                      <el-button @click="getOrderDetail" :icon="Search" />
                    </template>
                  </el-input>
                  <a href="javascript:void(0);" class="menu-link px-3" @click="open('orders')">View All</a>
                  <el-tooltip class="box-item" effect="dark" :content="constants.general.REFRESH" placement="top">
                    <button type="button" @click.prevent="getOrders()" class="btn btn-icon btn-light btn-sm">
                      <i class="bi bi-arrow-clockwise"></i>
                    </button>
                  </el-tooltip>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body py-3" v-loading="orderLoading">
            <Datatable :table-data="tableData" :table-header="tableHeader" :enable-items-per-page-dropdown="false" :empty-table-text="noDataFound" ref="updateProps">
              <template v-slot:cell-partner="{ row: data }">
                <div v-if="permission.isViewAllowed">
                  <el-tooltip class="box-item" effect="dark" :content="((data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name + '<br>' : '') + ((data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) ? '(' + data.trading_partner.user.name + ')' : '')" placement="top" raw-content>
                    <a href="javascript:void(0)" class="fw-bolder text-gray-600 text-hover-primary" @click="open('orders/' + data.id)">
                        <img :src="setImage(data.trading_partner.partner_image_path)" class="w-30px ms-n1" :alt="(data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name : '' "> <br>
                        {{ data.order_id }}
                    </a>
                  </el-tooltip>
                </div>
                <div v-else>
                  <el-tooltip class="box-item" effect="dark" :content="((data.trading_platforms && data.trading_platforms.platform_name) ? data.trading_platforms.platform_name + '<br>' : '') + ((data.trading_partner && data.trading_partner.user && data.trading_partner.user.name) ? data.trading_partner.user.name : '')" placement="top" raw-content>
                    <img :src="setImage(data.trading_partner.partner_image_path)" class="w-30px ms-n1" :alt="(data.trading_platforms && data.trading_platforms.platform_name) ? '(' + data.trading_platforms.platform_name + ')' : '' "> <br>
                    {{ data.order_id }}
                  </el-tooltip>
                </div>
              </template>
              <template v-slot:cell-customer_details="{ row: data }">
                <span v-if="data.customer_name || data.customer_phone || data.customer_email">
                  <dl class="row mb-0" v-if="data.customer_name || data.customer_phone">
                    <span v-if="data.customer_name">{{ data.customer_name }}
                      <span v-if="data.customer_phone">
                        <a :href="`tel:${data.customer_phone}`" class="text-gray-600 text-hover-primary mb-1">({{ data.customer_phone }})</a>
                      </span>
                    </span>
                  </dl>
                  <dl class="row mb-0" v-if="data.customer_email">
                    <span>
                      <a :href="`mailto:${data.customer_email}`" class="text-gray-600 text-hover-primary mb-1">{{ data.customer_email }}</a>
                    </span>
                  </dl>
                </span>
                <span v-else> - </span>
              </template>
              <template v-slot:cell-quantity="{ row: data }">
                <span v-if="data.order_products_count">
                  <span class="badge bg-primary">{{
                    data.order_products_count
                  }}</span>
                  <el-tooltip class="box-item" effect="dark" content="Pending Order Products to Approve. <br> <small> Click to go to pending orders </small>" placement="top" raw-content>
                    <a href="javascript:void(0)" @click="redirectToPendingOrder(data.pending_order.pending_order_id)">
                      <span v-if="data.pending_order && data.pending_order.pending_order_products_count" class="badge bg-warning ms-2">{{ data.pending_order.pending_order_products_count}}</span>
                    </a>
                  </el-tooltip>
                </span>
                <span v-else> - </span>
              </template>
              <template v-slot:cell-order_due_date="{ row: data }">
                <span v-if="data.order_due_date">
                  {{ formatDate(data.order_due_date) }}
                </span>
                <span v-else> - </span>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </div>
    <div class="row gy-5 g-xl-10">
      <div class="col-xl-5 mb-xl-10">
        <div class="card h-md-100">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">Today's Store Order Sales</span>
            </h3>
            <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a user">
              <el-date-picker v-model="storeRecordsByOrderDate" type="date" placeholder="Pick a Date" format="YYYY/MM/DD" value-format="YYYY-MM-DD" size="large" />
              &nbsp;
              <button type="button" @click.prevent="getPlatformStatistics()" class="btn btn-icon btn-light btn-sm text-right">
                <i class="bi bi-search"></i>
              </button>
              &nbsp;
              <el-tooltip class="box-item" effect="dark" :content="constants.general.REFRESH" placement="top">
                <button type="button" @click.prevent="resetPlateFormData()" class="btn btn-icon btn-light btn-sm">
                  <i class="bi bi-arrow-clockwise"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
          <div class="card-body py-3">
            <table class="table table-row-dashed fs-6 datatable gy-2 gs-2" v-loading="statisticsLoading">
              <thead>
                <tr class="text-start text-gray-800 fw-bolder fs-7 text-uppercase gs-0 text-center">
                  <th>
                    {{ constants.dashboard.PLATFORM_NAME }}
                  </th>
                  <th>
                    {{ constants.dashboard.STORE_NAME }}
                  </th>
                  <th>
                    {{ constants.dashboard.TOTAL_ORDER_PLACED }}
                  </th>
                  <th>
                    {{ constants.dashboard.TOTAL_ORDER_AMOUNT }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="platformStatistics.length">
                  <tr v-for="(platform, index) in platformStatistics" :key="index" v-bind:id="'row_' + index">
                    <td align="center">
                      <span class="text-muted me-2 fs-7 fw-bold">{{ platform.platform_name }}
                      </span>
                    </td>
                    <td align="center">
                      <span class="text-muted me-2 fs-7 fw-bold">{{ platform.store_name }}
                      </span>
                    </td>
                    <td align="center">
                      <span class="badge bg-primary">{{ platform.total_orders }}
                      </span>
                    </td>
                    <td align="center">
                      <span class="text-muted me-2 fs-7 fw-bold">{{ formatPrice(platform.total_sales) }}
                      </span>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="3" align="center">
                      {{ constants.general.NO_DATA_FOUND }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-xl-7 mb-5 mb-xl-10">
        <div class="card card-flush h-xl-100">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bolder fs-3 mb-1">Orders Watch List</span>
            </h3>
            <div class="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" title="Click to add a user">
              <el-tooltip class="box-item" effect="dark" :content="constants.general.REFRESH" placement="top">
                <button type="button" @click.prevent="getJobProgress()" class="btn btn-icon btn-light btn-sm">
                  <i class="bi bi-arrow-clockwise"></i>
                </button>
              </el-tooltip>
            </div>
          </div>
          <div class="card-body pt-2" v-loading="jobProgressLoading">
            <div class="mb-8 d-flex align-items-center" v-if="jobProgressData.oldData.length > 0">
              <span class="bullet bullet-vertical h-40px me-2" :style="`background-color: ${problemOrderColor}`"></span>
              <div class="flex-grow-1">
                <span>Problem &amp; Needs Immediate Attention</span>
              </div>
              <div class="col-md-10">
                <span v-for="(job, index) in jobProgressData.oldData" :key="index" v-bind:id="'row_' + index" align="center">
                  <template v-if="permission.isOrderViewAllowed">
                    <a href="javascript:void(0);" @click.prevent="$router.push({name: 'order-details',params: { id: job.id },})" class="badge fs-8 fw-bolder me-1 mb-1" :style="`background-color: ${problemOrderColor}`">{{ job.order_id }}</a>
                  </template>
                  <template v-else>
                    <span class="badge fs-8 fw-bolder me-1 mb-1" :style="`background-color: ${problemOrderColor}`">{{ job.order_id }}</span>
                  </template>
                </span>
              </div>
            </div>
            <div class="mb-8 d-flex align-items-center" v-if="jobProgressData.currentData.length > 0">
              <span class="bullet bullet-vertical h-40px me-2" :style="`background-color: ${slowOrderColor}`"></span>
              <div class="flex-grow-1"><span>Slow</span></div>
              <div class="col-md-10">
                <span v-for="(job, index) in jobProgressData.currentData" :key="index" v-bind:id="'row_' + index" align="center">
                  <template v-if="permission.isOrderViewAllowed">
                    <a href="javascript:void(0);" @click.prevent="$router.push({name: 'order-details', params: { id: job.id },})" class="badge fs-8 fw-bolder  me-1 mb-1" :style="`background-color: ${slowOrderColor}`">{{ job.order_id }}</a>
                  </template>
                  <template v-else>
                    <span class="badge fs-8 fw-bolder me-1 mb-1" :style="`background-color: ${slowOrderColor}`">{{ job.order_id }}</span>
                  </template>
                </span>
              </div>
            </div>
            <div class="mb-8 d-flex align-items-center" v-if="jobProgressData.futureData.length > 0">
              <span class="bullet bullet-vertical h-40px me-2" :style="`background-color: ${goodOrderColor}`"></span>
              <div class="flex-grow-1"><span>Success</span></div>
              <div class="col-md-10">
                <span v-for="(job, index) in jobProgressData.futureData" :key="index" v-bind:id="'row_' + index" align="center">
                  <template v-if="permission.isOrderViewAllowed">
                    <a href="javascript:void(0);" @click.prevent="$router.push({name: 'order-details',params: { id: job.id },})" class="badge fs-8 fw-bolder  me-1 mb-1" :style="`background-color: ${goodOrderColor}`">{{ job.order_id }}</a>
                  </template>
                  <template v-else>
                    <span class="badge fs-8 fw-bolder me-1 mb-1" :style="`background-color: ${goodOrderColor}`">{{ job.order_id }}</span>
                  </template>
                </span>
              </div>
            </div>
            <div v-if="emptyJobProgress == false" class="text-center">{{ constants.general.NO_DATA_FOUND }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { formatText, formatDate, formatPrice } from "@/core/helpers/common";
import { notificationFire } from "@/composable/notification.js";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import { useRoute, useRouter } from "vue-router";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { Search } from "@element-plus/icons-vue";
import StatisticsWidget5 from "@/components/widgets/statsistics/Widget5.vue";
import {
  loggedInUserRoles,
  loggedInUserPermissions,
} from "@/composable/get-roles.js";

export default defineComponent({
  name: "dashboard",
  components: {
    MixedWidget2,
    Datatable,
    StatisticsWidget5,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const orderLoading = ref(false);
    const orderStatisticsLoading = ref(false);
    const statisticsLoading = ref(false);
    const orderCountLoading = ref(false);
    const jobProgressLoading = ref(false);
    const emptyJobProgress = ref(null);
    const tableData = ref([]);
    const constants = globalConstant;
    const noDataFound = ref(constants.general.LOADINIG);
    const platformStatistics = reactive([]);
    const recordsOrderPerPage = 5;
    const updateProps = ref(null);
    const orderStatusByOrderDate = ref(null);
    const orderStatisticsByOrderDate = ref(null);
    const storeRecordsByOrderDate = ref("");
    const orderStatusChartLoading = ref(true);
    const problemOrderColor = ref('');
    const slowOrderColor = ref('');
    const goodOrderColor = ref('');
    const orderStatusChart = reactive({
      options: [],
      series: [],
    });

    const orderStatisticsChart = reactive({
      options: [],
      series: [],
    });

    const shortcuts = [
      {
        text: "Last week",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Last month",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "Last 3 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ];

    const dayPickerShortCut = [
      {
        text: "Today",
        value: new Date(),
      },
      {
        text: "Yesterday",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          return date;
        },
      },
      {
        text: "A week ago",
        value: () => {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          return date;
        },
      },
    ];
    const ordersCount = reactive({
      total_orders_count: 0,
      total_orders: 0,
      total_pending_orders: 0,
      total_completed_order: 0,
      order_percent_growth: 0,
      options: [],
      series: [],
    });

    const shippedUnshippedCount = reactive({
      shipped_count: 0,
      unshipped_count: 0,
    });

    const orderStatistics = reactive({
      orderdata: [],
    });
    const orderNo = ref("");
    const jobProgressData = reactive({
      oldData: [],
      currentData: [],
      futureData: [],
    });
    const searchParams = reactive({
      recordsPerPage: 10,
      pageNub: 1,
    });

    const permission = reactive({
      isViewAllowed: false,
      isOrderViewAllowed: false,
    });

    //Set Table header data
    const tableHeader = ref([
      {
        name: constants.orders.PARTNER,
        key: "partner",
        sortable: false,
        width: "w-150px",
        alignment: "text-center",
      },
      {
        name: constants.orders.CUSTOMER_DETAILS,
        key: "customer_details",
        sortable: true,
        width: "w-200px",
        alignment: "text-center",
      },
      {
        name: constants.orders.PRODUCT_ITEMS,
        key: "quantity",
        sortable: true,
        width: "w-100px",
        alignment: "text-center",
      },
      {
        name: constants.orders.ORDER_DUE_DATE,
        key: "order_due_date",
        width: "w-200px",
        sortable: false,
        alignment: "text-center",
      },
    ]);

    //Get all orders statistics
    const getOrderStatistics = async () => {
      await ApiService.post("dashboard/get-order-statistics", {
        orderStatisticsByOrderDate: orderStatisticsByOrderDate.value,
      })
        .then(({ data }) => {
          if (data.data) {
            if (orderStatistics.orderdata.length > 0) {
              orderStatistics.orderdata = [];
            }
            data.data.forEach((obj) => {
              if (obj.get_orders != "") {
                let tempData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (let i = 0; i < obj.get_orders.length; i++) {
                  tempData[obj.get_orders[i].month_name - 1] =
                    obj.get_orders[i].count;
                }
                orderStatistics.orderdata.push({
                  name: obj.platform_name,
                  data: tempData,
                });
              }
            });
          }
          orderStatisticsLoading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          orderStatisticsLoading.value = false;
        });
    };

    //Get all orders
    const getOrders = async () => {
      orderLoading.value = true;
      await ApiService.post("dashboard/get-orders", {
        recordsOrderPerPage: recordsOrderPerPage,
      })
        .then(({ data }) => {
          if (data.data && data.data.length) {
            tableData.value.splice(0, tableData.value.length, ...data.data);
          } else {
            tableData.value = [];
            updateProps.value.updateProps(
              0,
              searchParams.recordsPerPage,
              searchParams.pageNub,
              constants.general.NO_DATA_FOUND
            );
          }
          orderLoading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          tableData.value = [];
          orderLoading.value = false;
        });
    };

    //Get Platform Wise Order Statistics
    const getPlatformStatistics = async () => {
      statisticsLoading.value = true;
      await ApiService.post("dashboard/platform-statistics", {
        storeRecordsByOrderDate: storeRecordsByOrderDate.value,
      })
        .then(({ data }) => {
          platformStatistics.splice(0, platformStatistics.length, ...data.data);
          statisticsLoading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          statisticsLoading.value = false;
        });
    };

    //Get Job Progress Data
    const getJobProgress = async () => {
      jobProgressLoading.value = true;
      await ApiService.post("dashboard/job-progress")
        .then(({ data }) => {
          emptyJobProgress.value = (data.data.oldData || data.data.currentData || data.data.futureData) ? true : false;
          if(data.data.problem_order_color){
            problemOrderColor.value = data.data.problem_order_color
          }
          if(data.data.slow_order_color){
            slowOrderColor.value = data.data.slow_order_color
          }
          if(data.data.good_order_color){
            goodOrderColor.value = data.data.good_order_color
          }
          if (data.data.oldData) {
            jobProgressData.oldData = data.data.oldData;
          }
          if (data.data.currentData) {
            jobProgressData.currentData = data.data.currentData;
          }
          if (data.data.futureData) {
            jobProgressData.futureData = data.data.futureData;
          }
          jobProgressLoading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          jobProgressLoading.value = false;
        });
    };

    const resetOrderStatusChart = () => {
      orderStatusByOrderDate.value = "";
      getOrderStatusChartData();
    };

    const resetOrderStatistics = () => {
      orderStatisticsByOrderDate.value = "";
      getOrderStatistics();
    };

    const resetPlateFormData = () => {
      storeRecordsByOrderDate.value = "";
      getPlatformStatistics();
    };

    const getOrderDetail = () => {
      if (orderNo.value != "") {
        router.push({ name: "order-details", params: { id: orderNo.value } });
      }
    };

    const open = async (url) => {
      window.open(url, "_blank");
    }

    const setImage = (image) => {
        if (image == null) {
            return '/media/avatars/blank.png';
        }
      return process.env.VUE_APP_API_URL + "../../../partner_image/" + image;
    };

    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-300");
    var lightColor = getCSSVariableValue("--kt-info-light");
    var height = 50;

    orderStatisticsChart.options = {
      chart: {
        zoom: {
          enabled: false,
        },
        fontFamily: "inherit",
        type: "line",
        height: height,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          position: "front",
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " Orders";
          },
        },
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
    };

    orderStatisticsChart.series = orderStatistics.orderdata;

    const getOrderStatusChartData = async () => {
      await ApiService.post("dashboard/get-order-status-chartdata", {
        orderStatusByOrderDate: orderStatusByOrderDate.value,
      })
        .then(({ data }) => {
          if (data.data) {
            let option = data.data.map((value) => value.title);
            let series = data.data.map((value) => value.total);
            orderStatusChart.option = {
              chart: {
                width: 500,
                type: "pie",
              },
              labels: option,
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
              dataLabels: {
                formatter: function (val, opts) {
                  return opts.w.config.series[opts.seriesIndex] + " Orders";
                },
              },
            };
            orderStatusChart.series = series;
            orderStatusChartLoading.value = false;
          } else {
            orderStatusChart.series = [];
          }
        })
        .catch((error) => {
          //Toast the error here
          orderStatusChartLoading.value = false;
        });
    };

    const getOrderCountData = async () => {
      orderCountLoading.value = true;
      await ApiService.post("dashboard/get-orders-count")
        .then(({ data }) => {
          ordersCount.total_completed_order = data.data.completed_orders;
          ordersCount.total_orders = data.data.total_orders;
          ordersCount.total_pending_orders = data.data.total_pending_orders;
          ordersCount.total_orders_count = data.data.total_orders_count;
          ordersCount.order_percent_growth = data.data.order_percent;
          // getRoundChart()
          ordersCount.series = [
            data.data.completed_orders,
            data.data.total_orders,
            data.data.total_pending_orders,
          ];
          ordersCount.option = {
            colors: ["#009EF7", "#a0a1a5", "#F1416C"],
            labels: ["Completed Orders", "Orders", "Pending Orders"],
            chart: {
              width: 180,
              type: "donut",
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
            legend: {
              position: "right",
              offsetY: 0,
              height: 230,
              show: false,
            },
          };
          orderCountLoading.value = false;
        })
        .catch((error) => {
          //Toast the error here
          orderCountLoading.value = false;
        });
    };

    const getShippedUnshippedCount = async () => {
      orderStatusChartLoading.value = true;
      await ApiService.post("dashboard/get-shipped-unshipped-count")
        .then(({ data }) => {
          shippedUnshippedCount.shipped_count = data.data.shipped;
          shippedUnshippedCount.unshipped_count = data.data.unshipped;
        })
        .catch((error) => {
          //Toast the error here
          orderStatusChartLoading.value = false;
        });
    };

    onMounted(async () => {
      setCurrentPageTitle(formatText(route.name));
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("pullOrder") || role == "Super Admin")
        permission.isViewAllowed = true;
      if (per.includes("readOrder") || role == "Super Admin")
        permission.isOrderViewAllowed = true;
      getOrderStatistics();
      getOrders();
      getPlatformStatistics();
      getJobProgress();
      getOrderStatusChartData();
      getOrderCountData();
      getShippedUnshippedCount();
    });
    return {
      router,
      tableData,
      jobProgressData,
      platformStatistics,
      constants,
      getOrders,
      getPlatformStatistics,
      getJobProgress,
      orderLoading,
      orderStatisticsLoading,
      statisticsLoading,
      jobProgressLoading,
      formatDate,
      tableHeader,
      formatPrice,
      permission,
      noDataFound,
      Search,
      orderNo,
      getOrderDetail,
      orderStatistics,
      updateProps,
      emptyJobProgress,
      getOrderStatusChartData,
      orderStatusChartLoading,
      orderStatusChart,
      getOrderCountData,
      ordersCount,
      getShippedUnshippedCount,
      shippedUnshippedCount,
      orderStatusByOrderDate,
      shortcuts,
      resetOrderStatusChart,
      orderStatisticsByOrderDate,
      getOrderStatistics,
      resetOrderStatistics,
      orderStatisticsChart,
      dayPickerShortCut,
      storeRecordsByOrderDate,
      orderCountLoading,
      resetPlateFormData,
      setImage,
      problemOrderColor,
      slowOrderColor,
      goodOrderColor,
      open
    };
  },
});
</script>
<style sass>
.badge.arrowed,
.badge[class*="arrowed-"] {
  padding: 0.22rem 0.5rem 0.28rem;
  font-size: 0.75rem;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
.apexcharts-legend-series{
  text-align: left !important;
}
.el-popper.is-dark {
  text-align: center !important;
}
</style>